<template>
  <div class="common-layout">
    <el-container >
      <!-- :height="navIndex<3?'260px':'100px'" -->
      <el-header :height="navIndex<3?'320px':'100px'">
        <VHeader @changeIsCollapse="changeIsCollapse" @navIndex="setNavIndex" /> 
      </el-header>
      <el-container style="width: 1200px; margin: 0 auto;" :class="navIndex<3?'el-container1':'el-containers'">
        <router-view/>
      </el-container>
    </el-container>
    <!-- <div class="w100 bs" style="height: 60px;"></div> -->

    <div class="w100 bs flex align-center justify-center"
            style="height: 60px;background: #fff;color: #8c8c8c;font-size: 12px;">
            <span>© 版权所有 深圳市智垒网科技术有限公司 备案号：</span><a style="color: #8c8c8c;"
                href="https://beian.miit.gov.cn/#/Integrated/index" rel="noopener noreferrer"
                target="_blank">粤ICP备17071033号</a>
        </div>
  </div>
</template>

<script lang="ts">
import{ defineComponent,toRefs,reactive }from 'vue';
import VMenu from './components/menu.vue'
import VLogo from './components/logo.vue'
import VHeader from './components/header.vue'
export default defineComponent({
    name: "",
    components:{
        VMenu,
        VLogo,
        VHeader
    },
    setup: () => {
        const state = reactive({
            isCollapse:false,  // 控制菜单展开 收起
            navIndex: 0
        })
        const changeIsCollapse = ():void => {
            state.isCollapse = !state.isCollapse
        }
        const setNavIndex = (setNavIndex:number)=>{
          state.navIndex = setNavIndex;
        }
        return {
            ...toRefs(state),
            changeIsCollapse,
            setNavIndex
        }
    }
})
</script>
<style scoped>
.el-container1{
    /* min-height:calc(100vh - 320px); */
    min-height:calc(100vh - 380px);
}
.el-containers{
    /* min-height:calc(100vh - 160px); */
    min-height:calc(100vh - 160px);
}
</style>