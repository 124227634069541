<template>
    <div class="w100 bs" style="padding:10px 0 20px 0;">
        <div class="flex flex-direction align-center justify-center">
            <img src="@/assets/icon_nd.png" style="display: block;width: 192px;height: 120px;" alt="">
            <div class="text-16" style="margin-top: 16px;color: #707070;">
                暂无数据
            </div>
        </div>
    </div>
</template>

<script  lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    name: "",
    
})
</script>
<style scoped>

</style>