import axios from "axios";
import { ElLoading, ElMessage } from 'element-plus'
import router from "@/router/index";
const service = axios.create({
  baseURL: "", 
  timeout: 60000, 
  withCredentials: true,
});

// 添加请求拦截器
service.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    console.log(error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function(response) {
    console.log('response',response.data);
    const dataAxios = response.data;
    if(dataAxios.code == 90000 && dataAxios.msg){
      ElMessage.error(dataAxios.msg)
    }else if(dataAxios.code == 90001){
      window.localStorage.clear();
      router.push({ path: "/login" });
    }
    return dataAxios;
  },
  function(error) {
    console.log(error);
    return Promise.reject(error);
  }
);
export default service;
