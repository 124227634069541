
import{ defineComponent }from 'vue';
export default defineComponent({
    name: "",
    props:{
        isCollapse:{
            type:Boolean,
            default:true
        }
    },
    setup: () => {

    }
})
