// 导入axios实例
import httpRequest from "@/util/request";
var h = process.env.VUE_APP_API_URL;
// var h = '/api'
// 登录
function apiLogin(param: any) {
  return httpRequest({
    url: h+"/login.do",
    method: "post",
    data: param,
  });
}

// 发送验证码
function apiSendCode(param: any) {
  return httpRequest({
    url: h+"/p/sendCode.do",
    method: "post",
    data: param,
  });
}

// 基础数据	/base
//获取字典数据   @RequestMapping("/listDict.do")
function listDict(param: any) {
  return httpRequest({
    url: h+"/base/listDict.do",
    method: "post",
    data: param,
  });
}

//获取省市数据   @RequestMapping("/listCity.do")
export function listCity(param: any) {
  return httpRequest({
    url: h+"/base/listCity.do",
    method: "post",
    data: param,
  });
}

//职位类别       @RequestMapping("/listJobCategory.do")
export function listJobCategory(param: any) {
  return httpRequest({
    url: h+"/base/listJobCategory.do",
    method: "post",
    data: param,
  });
}

export function fileupload(param: any) {
  return httpRequest({
    url: h+"/sys/file/upload.do",
    method: "post",
    data: param,
  });
}


export function totalJob(param: any) {
  return httpRequest({
    url: h+"/hr/totalJob.do",
    method: "post",
    data: param,
  });
}

//HR业务	/hr
//聊天会话列表 @RequestMapping("/listChatInfo.do")
export function listChatInfo(param: any) {
  return httpRequest({
    url: h+"/hr/listChatInfo.do",
    method: "post",
    data: param,
  });
}


export function updateJobApplyMatchState(param: any) {
  return httpRequest({
    url: h+"/hr/updateJobApplyMatchState.do",
    method: "post",
    data: param,
  });
}

//消息列表 @RequestMapping("/listChatMsg.do")
export function listChatMsg(param: any) {
  return httpRequest({
    url: h+"/hr/listChatMsg.do",
    method: "post",
    data: param,
  });
}

//创建会话 @RequestMapping("/listChatMsg.do")
export function createChatInfo(param: any) {
  return httpRequest({
    url: h+"/hr/createChatInfo.do",
    method: "post",
    data: param,
  });
}

//轮询最新消息 @RequestMapping("/loopChatMsg.do")
export function loopChatMsg(param: any) {
  return httpRequest({
    url: h+"/hr/loopChatMsg.do",
    method: "post",
    data: param,
  });
}

//聊天 @RequestMapping("/sendChatMsg.do")
export function sendChatMsg(param: any) {
  return httpRequest({
    url: h+"/hr/sendChatMsg.do",
    method: "post",
    data: param,
  });
}

//投递/邀请投递简历 @RequestMapping("/saveJobApply.do")
export function saveJobApply(param: any) {
  return httpRequest({
    url: h+"/hr/saveJobApply.do",
    method: "post",
    data: param,
  });
}
//会话详情 @RequestMapping("/getChatInfo.do")
export function getChatInfo(param: any) {
  return httpRequest({
    url: h+"/hr/getChatInfo.do",
    method: "post",
    data: param,
  });
}
//同意交换电话 @RequestMapping("/同意交换电话.do")
export function agreeExchangePhone(param: any) {
  return httpRequest({
    url: h+"/hr/agreeExchangePhone.do",
    method: "post",
    data: param,
  });
}



//当前登录信息 @RequestMapping("/getUserInfo.do")
export function getUserInfo(param: any) {
  return httpRequest({
    url: h+"/hr/getUserInfo.do",
    method: "post",
    data: param,
  });
}

//添加或修改公司信息 @RequestMapping("/saveHunterCompany.do")
export function saveHunterCompany(param: any) {
  return httpRequest({
    url: h+"/hr/saveHunterCompany.do",
    method: "post",
    data: param,
  });
}

//修改用户信息 @RequestMapping("/updateUser.do")
export function updateUser(param: any) {
  return httpRequest({
    url: h+"/hr/updateUser.do",
    method: "post",
    data: param,
  });
}

//发布职位 @RequestMapping("/addHunterJob.do")
export function addHunterJob(param: any) {
  return httpRequest({
    url: h+"/hr/addHunterJob.do",
    method: "post",
    data: param,
  });
}

//职位关键词 @RequestMapping("/listJobKeyword.do")
export function listJobKeyword(param: any) {
  return httpRequest({
    url: h+"/base/listJobKeyword.do",
    method: "post",
    data: param,
  });
}

//职位亮点 @RequestMapping("/listJobLight.do")
export function listJobLight(param: any) {
  return httpRequest({
    url: h+"/base/listJobLight.do",
    method: "post",
    data: param,
  });
}

//职位列表 @RequestMapping("/listHunterJob.do")
export function listHunterJob(param: any) {
  return httpRequest({
    url: h+"/hr/listHunterJob.do",
    method: "post",
    data: param,
  });
}

//职位详情 @RequestMapping("/getHunterJob.do")
export function getHunterJob(param: any) {
  return httpRequest({
    url: h+"/hr/getHunterJob.do",
    method: "post",
    data: param,
  });
}

//更新职位状态 @RequestMapping("/updateHunterJobState.do")
export function updateHunterJobState(param: any) {
  return httpRequest({
    url: h+"/hr/updateHunterJobState.do",
    method: "post",
    data: param,
  });
}

//浏览求职者 @RequestMapping("/viewJobSeeker.do")
export function viewJobSeeker(param: any) {
  return httpRequest({
    url: h+"/hr/viewJobSeeker.do",
    method: "post",
    data: param,
  });
}

//简历列表 @RequestMapping("/listSeekerResumeInfo.do") 优质人才
export function listSeekerResumeInfo(param: any) {
  return httpRequest({
    url: h+"/hr/listSeekerResumeInfo.do",
    method: "post",
    data: param,
  });
}

//简历详情
export function getSeekerResumeInfo(param: any) {
  return httpRequest({
    url: h+"/hr/getSeekerResumeInfo.do",
    method: "post",
    data: param,
  });
}

//职位应聘列表 @RequestMapping("/listJobApply.do")
export function listJobApply(param: any) {
  return httpRequest({
    url: h+"/hr/listJobApply.do",
    method: "post",
    data: param,
  });
}

//HR浏览记录 @RequestMapping("/listJobHunterView.do")
export function listJobHunterView(param: any) {
  return httpRequest({
    url: h+"/hr/listJobHunterView.do",
    method: "post",
    data: param,
  });
}

//求职者浏览职位 @RequestMapping("/listJobSeekerView.do")
export function listJobSeekerView(param: any) {
  return httpRequest({
    url: h+"/hr/listJobSeekerView.do",
    method: "post",
    data: param,
  });
}

// Integer page = msg.getInteger("page");
// Integer pageSize = msg.getInteger("pageSize");
// Assert.notNull(page, "page 为空");
// Assert.notNull(pageSize, "pageSize 为空");

// String resumeKeyword = msg.getString("resumeKeyword");  //简历关键词
// String formerCompany = msg.getString("formerCompany");  //就职过的公司
// String liveCity = msg.getString("liveCity");    //居住城市
// String intendCity = msg.getString("liveCity");  //期望工作城市
// Integer intendJobCategory = msg.getInteger("intendJobCategory");    //期望职位类别
// Integer intendSalary = msg.getInteger("intendSalary");    //期望月薪
// String experience = msg.getString("experience"); //经验年限
// String education = msg.getString("education"); //学历
// String gender = msg.getString("gender"); //性别
// String matchState = msg.getString("matchState"); //应聘状态
// String applyWay = msg.getString("applyWay"); //投递方式

export function salaryOption() {
  return [
    { salaryMin: 0, salaryMax: 1500, label: "1.5k以下" },
    { salaryMin: 1500, salaryMax: 2000, label: "1.5-2k" },
    { salaryMin: 2000, salaryMax: 3000, label: "2-3k" },
    { salaryMin: 3000, salaryMax: 4500, label: "3-4.5k" },
    { salaryMin: 4500, salaryMax: 6000, label: "4.5-6k" },
    { salaryMin: 6000, salaryMax: 8000, label: "6-8k" },
    { salaryMin: 8000, salaryMax: 10000, label: "8-10k" },
    { salaryMin: 10000, salaryMax: 15000, label: "10-15k" },
    { salaryMin: 15000, salaryMax: 20000, label: "15-20k" },
    { salaryMin: 20000, salaryMax: 25000, label: "20-25k" },
    { salaryMin: 25000, salaryMax: 9999999, label: "25k以上" }
  ];
}




export { apiLogin, apiSendCode, listDict };
