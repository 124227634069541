
import{ defineComponent,toRefs,reactive,onMounted,watch,computed,ref }from 'vue';
import router from '@/router/index'
import store from '@/store/index'
import { Store } from 'vuex'
export default defineComponent({
    name: "",
    props:{
        isCollapse:{
            type:Boolean,
            default:true
        }
    },
    setup: (props) => {
        watch(() => {
            return props.isCollapse
        },(n) => {
            console.log(n)
        })
        const handleOpen = (key:number, keyPath:string):void => {
            console.log(key, keyPath);
        }
        const handleClose = (key:number, keyPath:string):void => {
            console.log(key, keyPath);
        }
        let currentRoutePath = ref(router.currentRoute.value.path)
        const $store: Store<any> = store
        const selectMenu = (item:any):void => {
            currentRoutePath.value = item.meta.url
            router.push({path:item.meta.url})
        }
        const menus = computed(() => {
            return $store.state.user.routers
        })
        return {
            handleOpen,
            handleClose,
            menus,
            selectMenu,
            currentRoutePath
        }
    }
})
