
import{ defineComponent,toRefs,reactive }from 'vue';
import VMenu from './components/menu.vue'
import VLogo from './components/logo.vue'
import VHeader from './components/header.vue'
export default defineComponent({
    name: "",
    components:{
        VMenu,
        VLogo,
        VHeader
    },
    setup: () => {
        const state = reactive({
            isCollapse:false,  // 控制菜单展开 收起
            navIndex: 0
        })
        const changeIsCollapse = ():void => {
            state.isCollapse = !state.isCollapse
        }
        const setNavIndex = (setNavIndex:number)=>{
          state.navIndex = setNavIndex;
        }
        return {
            ...toRefs(state),
            changeIsCollapse,
            setNavIndex
        }
    }
})
