
import { defineComponent, reactive, toRefs, watch, computed } from "vue";
import router from "@/router/index";
import { useStore } from 'vuex'
export default defineComponent({
  name: "",
  setup: (props, { emit }) => {
    const store = useStore();
    const state = reactive({
      selIndex: 0,
      navlist: [
        {
          label: "首页",
          value: "/home",
          name: 'Home'
        },
        {
          label: "找人",
          value: "/look/index",
          name: 'look'
        },
        {
          label: "职位",
          value: "/position/index",
          name: 'position'
        },
        {
          label: "消息",
          value: "/news/index",
          name: 'news'
        },
        {
          label: "账号中心",
          value: "/accountCenter/index",
          name: 'accountCenter'
        },
        {
          label: "数据统计",
          value: "/statistics/index",
          name: 'statistics'
        },
      ],
      userInfo: computed(() => store.state.user.userInfo)
    });
    var routetFIndex = state.navlist.findIndex(item => item.name == router.currentRoute.value.name);
    if (router.currentRoute.value.name == 'releasePosition' || router.currentRoute.value.name == 'jobDetails') {
      routetFIndex = 2;
    }
    console.log('router.currentRoute.value', router.currentRoute.value)
    if (router.currentRoute.value.name == 'resume') {
      routetFIndex = 1;
    }


    state.selIndex = routetFIndex >= 0 ? routetFIndex : state.selIndex;

    emit('navIndex', state.selIndex)
    const logOut = () => {
      window.localStorage.clear();
      router.push({ path: "/login" });
    };
    const navClcik = (pathurl: string, pathIndex: number): void => {
      console.log(pathurl);
      state.selIndex = pathIndex;

      router.push({ path: pathurl });
    };
    watch(() => state.selIndex, (newValue, oldValue) => {
      console.log('watch 已触发', newValue)
      emit('navIndex', newValue)
      }
    )
    watch(() => router.currentRoute.value.name, (newValue, oldValue) => {
      console.log(11111111111111, newValue)
      if (newValue == 'releasePosition' || newValue == 'jobDetails') {
        state.selIndex = 2;
      } else if (newValue == 'resume') {
        state.selIndex = 1;
      } else {
        let hasIndex = state.navlist.findIndex((item: any) => item.name == newValue);
        if (hasIndex >= 0) {
          state.selIndex = hasIndex;
        }
      }
    }, { immediate: true })
    return {
      logOut,
      navClcik,
      ...toRefs(state),
    };
  },
});
