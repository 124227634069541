
import { defineComponent } from 'vue';
import { ElConfigProvider } from 'element-plus'
import store from "@/store/index";
import { Store } from "vuex";
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
  name: "App",
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  setup: () => {
    const $store: Store<any> = store;
    $store.dispatch("GET_USERINFO");
    const fn = () => {
      location.reload()
    }
    let locale = zhCn
    return {
      locale
    }
  }
})
