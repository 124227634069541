import { initRouter } from "@/router/index";
import router from "@/router/index";

import axios from "axios";
import { getUserInfo } from "@/util/apis";

const user = {
  state: {
    routers: JSON.parse(localStorage.getItem("v3-element-plus-menu") || "[]"),
    loginState: false,
    userInfo: {},
    companyInfo:{}
  },
  mutations: {
    SET_ROUTERS(state: any, routes: any) {
      state.routers = routes;
    },
    SET_LOGIN_STATE(state: any, loginState: any) {
      state.loginState = loginState;
    },
    SET_USERINFO(state: any, userInfo: any) {
      state.userInfo = userInfo;
    },
    SET_COMPANYINFO(state: any, companyInfo: any) {
      state.companyInfo = companyInfo;
    },
  },
  actions: {
    GET_ROUTERS_DATA(ctx: any) {
      getUserInfo({}).then((res) => {
        console.log("getuserinfo==", res);
        ctx.commit("SET_USERINFO", res.data.userInfo);
        if (res.data.companyInfo) {
          ctx.commit("SET_COMPANYINFO", res.data.companyInfo);
          axios.get("/menu.json").then((res) => {
            localStorage.setItem(
              "v3-element-plus-menu",
              JSON.stringify(res.data.menu)
            );
            ctx.commit("SET_ROUTERS", res.data.menu);
            ctx.commit("SET_LOGIN_STATE", true);
            initRouter(res.data.menu, undefined); // 拼接路由信息
            res.data.menu.forEach((rt: any) => {
              router.addRoute(rt); // 添加路由信息到实例
            });
            router.push({ path: "/home" });
          });
        }else{
          router.push({ path: "/companyInfo" });
        }
      });
    },
    GET_USERINFO(ctx: any) {
      getUserInfo({}).then((res) => {
        console.log("getuserinfo==", res);
        ctx.commit("SET_USERINFO", res.data.userInfo);
        if(res.data.companyInfo){
          ctx.commit("SET_COMPANYINFO", res.data.companyInfo);
        }
      });
    },
    REGISTER_USER(ctx: any) {},
  },
};

export default user;
