import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);

import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "dayjs/locale/zh-cn";
import locale from "element-plus/lib/locale/lang/zh-cn";
import nodatadf from "./views/components/nodatadf.vue";

app.component('nodatadf',nodatadf);

app
  .use(store)
  .use(ElementPlus, { locale})
  .use(router)
  .mount("#app");

  
  